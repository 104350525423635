import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { omit, isEqual } from 'lodash';

import { FormBuilder, FeatureFooter, TimeStamp } from '../../../../framework';
import { InputLabel } from '../../../../framework/inputs';
import {
  savePersonDetails,
  resetPersonDetails,
  updatePersonDetails,
  updatePersonIngestedAction,
  setShowPersonValidationModal,
} from '../../../../store/actions/person';
import { showModal } from '../../../../store/actions/globalActions';
import {
  getPersonBasicForAPI,
  personAPIKeys,
  nameFormation,
  convertDropdownDataforAPI,
} from '../../../../store/helpers/person';
import {
  searchDegree,
  searchSpeciality,
  searchOrganization,
} from '../../../../utils/person/helper';
import SupportingUrls from '../../../../generic/features/urls';
import { MODAL_MESSAGES, childFormChangeObject } from '../../../../utils/generic/constants';
import ClearConfirmation from '../../../../generic/modals/clearConfirmation';
import {
  convertArrayToString,
  formatDate,
  updateIngestedDataBasedOnFieldType,
  checkForIngestionAction,
  validateIngestionAction,
  isDataEqual,
  checkUserIngestionAction,
  checkIfObjectIsEmpty,
  addInceptionDate,
} from '../../../../utils/generic/helper';
import ConfirmationMessage from '../../../../generic/modals/ConfirmationMessage';
import { layoutSchema, formSchema, validate } from './config';
import PersonValidationModal from './personValidationModal';
import './index.scss';

export const BasicAndContactInfo = forwardRef(
  ({ data, permissions: { disableEdit }, inceptionDate }, ref) => {
    const {
      original: {
        personBasicInfo: originalPersonBasicInfo,
        recordInceptionDate,
        showPersonValidationModal,
        personValidationMessages,
      },
      current: { id, personBasicInfo, ingestedId, ingestedProductionStatusId },
      ingested: {
        personBasicInfo: { data: ingestedData, fieldActions: ingestedActions },
      },
      masterData: { personTypes, personLicenseTypes, supportingUrlStatuses },
    } = useSelector(state => state.person, shallowEqual);

    const dispatch = useDispatch();
    const basicInfoForm = useRef(null);
    const history = useHistory();
    const [nextReviewDate, setNextReviewDate] = useState(null);
    const [updateNextReviewConfirmation, setUpdateNextReviewConfirmation] = useState(false);
    const [firstTimeAlert, setFirstTimeAlert] = useState(true);
    const [selectedNextReviewDateDate, setSelectedNextReviewDateDate] = useState(null);
    const [clearModal, setClearModal] = useState(false);
    let initialValues = { ...personBasicInfo };

    if (personBasicInfo.address) {
      {
        if (personBasicInfo.addressId !== null && personBasicInfo.addressId !== undefined) {
          let userSelectedAddress = personBasicInfo.parentOrganization.address.filter(address => {
            return address.isPrimary;
          });

          if (userSelectedAddress.length > 0) {
            if (personBasicInfo.address.length > 1) {
              initialValues = {
                ...personBasicInfo,
                parentOrganization: {
                  ...personBasicInfo.parentOrganization,
                  label: `${personBasicInfo.parentOrganization.id} - ${personBasicInfo.parentOrganization.name} - ${userSelectedAddress[0].fullAddress}`,
                },
                address: userSelectedAddress[0].fullAddress,
                phone: userSelectedAddress[0].phoneNumbers
                  ? convertArrayToString(userSelectedAddress[0].phoneNumbers)
                  : '',
                fax: userSelectedAddress[0].faxNumbers
                  ? convertArrayToString(userSelectedAddress[0].faxNumbers)
                  : '',
                email: userSelectedAddress[0].emails
                  ? convertArrayToString(userSelectedAddress[0].emails)
                  : '',
              };
            } else if (personBasicInfo.address.length === 1) {
              initialValues = {
                ...personBasicInfo,
                address: personBasicInfo.parentOrganization.displayAddress,
              };
            }
          } else {
            initialValues = {
              ...personBasicInfo,
              address: personBasicInfo.parentOrganization.displayAddress,
            };
          }
        } else {
          initialValues = {
            ...personBasicInfo,
            address: personBasicInfo.parentOrganization.displayAddress,
          };
        }
      }
    }

    let updatedBasicAndContactInfoDetails = {
      data: {},
      ingestedContentResults: [],
      tabData: null,
      isValidRequired: true,
    };
    const supportingUrlsFormRef = useRef(childFormChangeObject);
    const licensesFormRef = useRef(childFormChangeObject);
    const phoneNumbersFormRef = useRef(childFormChangeObject);
    const faxNumbersFormRef = useRef(childFormChangeObject);
    const emailsFormRef = useRef(childFormChangeObject);

    useEffect(() => {
      if (data.hasError && !data.hasIngestedData) {
        handleSave(null);
      }

      return () => {
        const formRef = basicInfoForm.current;
        if (formRef) {
          dispatch(
            updatePersonDetails({
              key: personAPIKeys.BASIC_INFO,
              data: formRef.values,
            }),
          );
        }
      };
    }, []);

    useEffect(() => {
      setFirstTimeAlert(true);
    }, [originalPersonBasicInfo.recordDates.nextReviewDate]);

    useEffect(() => {
      setNextReviewDate(personBasicInfo.recordDates.nextReviewDate);
    }, [personBasicInfo]);

    useImperativeHandle(ref, () => ({
      isChanged,
      validate: () => {
        if (basicInfoForm.current) {
          if (data.hasIngestedData) {
            return validateIngestionAction({
              ingestedActions,
              data: basicInfoForm.current.values,
              type: 'person',
            }).isValid;
          }
          if (!checkIfObjectIsEmpty(validate(basicInfoForm.current.values))) {
            handleSave();
            return false;
          }
        }
        return true;
      },
    }));

    const isChanged = () => {
      const updatedOriginalPersonBasicInfo = omit(originalPersonBasicInfo, [
        'address',
        'parentOrganization',
        'email',
        'phone',
        'fax',
      ]);
      const updatedValues = omit(basicInfoForm.current.values, [
        'address',
        'parentOrganization',
        'email',
        'phone',
        'fax',
      ]);

      if (data.hasIngestedData) {
        return checkUserIngestionAction(basicInfoForm.current.values, ingestedActions);
      }
      return (
        licensesFormRef.current.isChanged() ||
        supportingUrlsFormRef.current.isChanged() ||
        phoneNumbersFormRef.current.isChanged() ||
        faxNumbersFormRef.current.isChanged() ||
        emailsFormRef.current.isChanged() ||
        !isDataEqual(updatedValues, updatedOriginalPersonBasicInfo)
      );
    };

    const convertDropdownData = item => ({
      ...item,
      id: item.value,
      value: item.label,
    });

    const handleIngestionActions = (option, key, type) => {
      let ingested = ingestedData[key];
      let original = originalPersonBasicInfo[key];

      if (key === 'personType') {
        ingested = ingested ? convertDropdownDataforAPI(ingested) : null;
        original = original ? convertDropdownDataforAPI(original) : null;
      } else if (key === 'parentOrganization') {
        ingested = ingested ? convertDropdownData(ingested) : null;
        original = original ? convertDropdownData(original) : null;
      }

      const { setFieldValue } = basicInfoForm.current;
      const updatedData = updateIngestedDataBasedOnFieldType({
        type,
        original,
        ingestionAction: option,
        current: personBasicInfo[key],
        ingested,
      });

      if (key !== 'personType' && key !== 'parentOrganization') {
        onNameChange({ key, value: updatedData });
      } else if (key === 'parentOrganization') {
        setOrgDependentFields(updatedData || {});
      }

      setFieldValue(key, updatedData);
      dispatch(
        updatePersonIngestedAction({
          parentKey: 'personBasicInfo',
          childKey: key,
          ingestionAction: option,
        }),
      );
    };

    const handleIngestionActionsForMultiSelect = ({
      option,
      ingested,
      key,
      childKey,
      disableKey,
    }) => {
      const { values, setFieldValue } = basicInfoForm.current;
      const updatedData = updateIngestedDataBasedOnFieldType({
        type: 'array',
        ingestionAction: option,
        current: values[key],
        ingested,
        childKey,
      });
      setFieldValue(key, updatedData);
      updatedFormSchema = {
        ...updatedFormSchema,
        [key]: {
          ...updatedFormSchema[key],
          props: {
            ...updatedFormSchema[key].props,
            [disableKey]: checkForIngestionAction(updatedData),
          },
        },
      };
    };

    const filterDegree = async val => {
      const filteredData = await searchDegree(val);
      if (!filteredData) {
        dispatch(showModal(MODAL_MESSAGES.API_ERROR));
      }
      return filteredData;
    };

    const filterSpeciality = async val => {
      const filteredData = await searchSpeciality(val);
      if (!filteredData) {
        dispatch(showModal(MODAL_MESSAGES.API_ERROR));
      }
      return filteredData;
    };

    const handleDateChange = selectedDate => {
      const formatSelectedDate = selectedDate ? formatDate(selectedDate) : null;
      const formatPrevSelectedDate = nextReviewDate ? formatDate(nextReviewDate) : null;
      if (
        !isEqual(formatSelectedDate, formatPrevSelectedDate) &&
        firstTimeAlert &&
        nextReviewDate
      ) {
        setUpdateNextReviewConfirmation(true);
        setNextReviewDate(selectedDate);
        setSelectedNextReviewDateDate(selectedDate);
      } else if (!isEqual(formatSelectedDate, formatPrevSelectedDate)) {
        setNextReviewDate(selectedDate);
        const { setFieldValue, values } = basicInfoForm.current;
        setFieldValue('recordDates', {
          ...values.recordDates,
          nextReviewDate: selectedDate,
        });
      }
    };

    const proceedToUpdateNextReviewDate = flag => {
      const { setFieldValue, values } = basicInfoForm.current;
      if (flag) {
        setNextReviewDate(selectedNextReviewDateDate);
        setFirstTimeAlert(false);
        setFieldValue('recordDates', {
          ...values.recordDates,
          nextReviewDate: selectedNextReviewDateDate,
        });
      } else {
        setNextReviewDate(personBasicInfo.recordDates.nextReviewDate);
        setFieldValue('recordDates', {
          ...values.recordDates,
          nextReviewDate: personBasicInfo.recordDates.nextReviewDate,
        });
      }
      setUpdateNextReviewConfirmation(false);
    };

    const onAddOrganization = () => {
      const win = window.open('/organization/0', '_blank');
      win.focus();
    };

    const onSearchOrganization = async val => {
      const filteredData = await searchOrganization(val);
      let k = 0;
      let updatedFilterData = [];

      for (let i = 0; i < filteredData.length; i++) {
        if (filteredData[i].address.length > 1) {
          for (let j = 0; j < filteredData[i].address.length; j++) {
            if (!filteredData[i].address[j].isPrimary) continue;
            updatedFilterData[k] = filteredData[i];
            updatedFilterData[k] = {
              ...updatedFilterData[k],
              addressId: filteredData[i].address[j].id,
              address: [filteredData[i].address[j].fullAddress],
              displayAddress: [filteredData[i].address[j].fullAddress]
                ? convertArrayToString([filteredData[i].address[j].fullAddress])
                : '',
              label: `${filteredData[i].id} - ${filteredData[i].name} - ${filteredData[i].address[j].fullAddress}`,
              phone: filteredData[i].address[j].phoneNumbers
                ? convertArrayToString(filteredData[i].address[j].phoneNumbers)
                : '',
              fax: filteredData[i].address[j].faxNumbers
                ? convertArrayToString(filteredData[i].address[j].faxNumbers)
                : '',
              email: filteredData[i].address[j].emails
                ? convertArrayToString(filteredData[i].address[j].emails)
                : '',
            };
            k++;
          }
        } else {
          updatedFilterData[k] = {
            ...filteredData[i],
            address: filteredData[i].displayAddress,
          };
          k++;
        }
      }
      if (!filteredData) {
        dispatch(showModal(MODAL_MESSAGES.API_ERROR));
      }
      return updatedFilterData;
    };

    const onNameChange = ({ key, value }) => {
      if (basicInfoForm.current) {
        const name = nameFormation({
          ...basicInfoForm.current.values,
          [key]: value,
        });
        basicInfoForm.current.setFieldValue('name', name);
      }
    };

    const setOrgDependentFields = ({
      primaryContactInfo = null,
      displayAddress = '',
      parentOrganizationName = '',
    }) => {
      const orgDetails = {
        parentOrganizationName: parentOrganizationName || '',
        address: displayAddress || '',
        phone: primaryContactInfo ? convertArrayToString(primaryContactInfo.phoneNumbers) : '',
        fax: primaryContactInfo ? convertArrayToString(primaryContactInfo.faxNumbers) : '',
        email: primaryContactInfo ? convertArrayToString(primaryContactInfo.emails) : '',
      };
      Object.keys(orgDetails).forEach(key => {
        if (basicInfoForm.current) {
          basicInfoForm.current.setFieldValue(key, orgDetails[key]);
        }
      });
    };
    const navigateParentOrganization = () => {
      if (
        basicInfoForm.current &&
        basicInfoForm.current.values.parentOrganization &&
        basicInfoForm.current.values.parentOrganization.id
      ) {
        return `/organization/${basicInfoForm.current.values.parentOrganization.id}`;
      }
    };

    let updatedFormSchema = {
      ...formSchema,
      parentOrganization: {
        ...formSchema.parentOrganization,
        props: {
          ...formSchema.parentOrganization.props,
          onFilter: onSearchOrganization,
          ingestionAction: ingestedActions['parentOrganization'],
          ingestedData: ingestedData['parentOrganization']
            ? convertDropdownData(ingestedData['parentOrganization'])
            : null,
          handleIngestionActions: option =>
            handleIngestionActions(option, 'parentOrganization', 'object'),
          isDisabled: checkForIngestionAction(ingestedActions['parentOrganization']),
          isIngestionActionDisabled: disableEdit,
        },
      },
      parentOrganizationName: {
        ...formSchema.parentOrganizationName,

        props: {
          onNavigation: navigateParentOrganization,
        },
      },
      button: {
        ...formSchema.button,
        props: {
          ...formSchema.button.props,
          onClick: onAddOrganization,
        },
      },
      firstName: {
        ...formSchema.firstName,
        props: {
          ...formSchema.firstName.props,
          handleInputChange: onNameChange,
          disabled: checkForIngestionAction(ingestedActions['firstName']),
          isIngestionActionDisabled: disableEdit,
          ingestionAction: ingestedActions['firstName'],
          ingestedData: ingestedData['firstName'],
          handleIngestionActions: option => handleIngestionActions(option, 'firstName', 'text'),
        },
      },
      lastName: {
        ...formSchema.lastName,
        props: {
          ...formSchema.lastName.props,
          handleInputChange: onNameChange,
          disabled: checkForIngestionAction(ingestedActions['lastName']),
          isIngestionActionDisabled: disableEdit,
          ingestionAction: ingestedActions['lastName'],
          ingestedData: ingestedData['lastName'],
          handleIngestionActions: option => handleIngestionActions(option, 'lastName', 'text'),
        },
      },
      middleName: {
        ...formSchema.middleName,
        props: {
          ...formSchema.middleName.props,
          handleInputChange: onNameChange,
          disabled: checkForIngestionAction(ingestedActions['middleName']),
          isIngestionActionDisabled: disableEdit,
          ingestionAction: ingestedActions['middleName'],
          ingestedData: ingestedData['middleName'],
          handleIngestionActions: option => handleIngestionActions(option, 'middleName', 'text'),
        },
      },
      nameSuffix: {
        ...formSchema.nameSuffix,
        props: {
          ...formSchema.nameSuffix.props,
          handleInputChange: onNameChange,
          disabled: checkForIngestionAction(ingestedActions['nameSuffix']),
          isIngestionActionDisabled: disableEdit,
          ingestionAction: ingestedActions['nameSuffix'],
          ingestedData: ingestedData['nameSuffix'],
          handleIngestionActions: option => handleIngestionActions(option, 'nameSuffix', 'text'),
        },
      },
      personType: {
        ...formSchema.personType,
        options: personTypes.map(type => ({ value: type.id, label: type.value })),
        props: {
          ...formSchema.personType.props,
          ingestionAction: ingestedActions['personType'],
          ingestedData: ingestedData['personType']
            ? convertDropdownDataforAPI(ingestedData['personType'])
            : null,
          handleIngestionActions: option => handleIngestionActions(option, 'personType', 'object'),
          isDisabled: checkForIngestionAction(ingestedActions['personType']),
          isIngestionActionDisabled: disableEdit,
        },
      },
      licenses: {
        ...formSchema.licenses,
        options: personLicenseTypes.map(type => ({
          ...type,
          value: type.id,
          label: type.value,
        })),
        props: {
          ...formSchema.licenses.props,
          handleIngestionActions: (option, ingested) =>
            handleIngestionActionsForMultiSelect({
              option,
              ingested,
              key: 'licenses',
              childKey: 'id',
              disableKey: 'disabled',
            }),
          disabled: checkForIngestionAction(
            basicInfoForm.current ? basicInfoForm.current.values.licenses : [],
          ),
          isIngestionActionDisabled: disableEdit,
          ref: licensesFormRef,
        },
      },
      specialities: {
        ...formSchema.specialities,
        props: {
          ...formSchema.specialities.props,
          onFilter: filterSpeciality,
          handleIngestionActions: (option, ingested) =>
            handleIngestionActionsForMultiSelect({
              option,
              ingested,
              key: 'specialities',
              childKey: 'id',
              disableKey: 'isDisabled',
            }),
          isDisabled: checkForIngestionAction(
            basicInfoForm.current ? basicInfoForm.current.values.specialities : [],
          ),
          isIngestionActionDisabled: disableEdit,
        },
      },
      degrees: {
        ...formSchema.degrees,
        props: {
          ...formSchema.degrees.props,
          onFilter: filterDegree,
          handleIngestionActions: (option, ingested) =>
            handleIngestionActionsForMultiSelect({
              option,
              ingested,
              key: 'degrees',
              childKey: 'id',
              disableKey: 'isDisabled',
            }),
          isDisabled: checkForIngestionAction(
            basicInfoForm.current ? basicInfoForm.current.values.degrees : [],
          ),
          isIngestionActionDisabled: disableEdit,
        },
      },
      phoneNumbers: {
        ...formSchema.phoneNumbers,
        props: {
          ...formSchema.phoneNumbers.props,
          handleIngestionActions: (option, ingested) =>
            handleIngestionActionsForMultiSelect({
              option,
              ingested,
              key: 'phoneNumbers',
              childKey: 'text',
              disableKey: 'disabled',
            }),
          disabled: checkForIngestionAction(
            basicInfoForm.current ? basicInfoForm.current.values.phoneNumbers : [],
          ),
          isIngestionActionDisabled: disableEdit,
          ref: phoneNumbersFormRef,
        },
      },
      faxNumbers: {
        ...formSchema.faxNumbers,
        props: {
          ...formSchema.faxNumbers.props,
          handleIngestionActions: (option, ingested) =>
            handleIngestionActionsForMultiSelect({
              option,
              ingested,
              key: 'faxNumbers',
              childKey: 'text',
              disableKey: 'disabled',
            }),
          disabled: checkForIngestionAction(
            basicInfoForm.current ? basicInfoForm.current.values.faxNumbers : [],
          ),
          isIngestionActionDisabled: disableEdit,
          ref: faxNumbersFormRef,
        },
      },
      emails: {
        ...formSchema.emails,
        props: {
          ...formSchema.emails.props,
          handleIngestionActions: (option, ingested) =>
            handleIngestionActionsForMultiSelect({
              option,
              ingested,
              key: 'emails',
              childKey: 'text',
              disableKey: 'disabled',
            }),
          disabled: checkForIngestionAction(
            basicInfoForm.current ? basicInfoForm.current.values.emails : [],
          ),
          isIngestionActionDisabled: disableEdit,
          ref: emailsFormRef,
        },
      },
    };
    const handleSave = (tabData, isValidRequired) => {
      if (basicInfoForm.current) {
        const { submitForm, values } = basicInfoForm.current;
        const {
          isValid,
          data: basicAndContactInfoDetails,
          ingestedContentResults,
        } = validateIngestionAction({
          ingestedActions,
          data: values,
          type: 'person',
        });
        if (isValid) {
          updatedBasicAndContactInfoDetails = {
            data: basicAndContactInfoDetails,
            ingestedContentResults,
            tabData,
            isValidRequired: isValidRequired,
          };
          submitForm();
        } else {
          dispatch(showModal(MODAL_MESSAGES.INGESTION_ERROR));
        }
      }
    };

    const resetPersonForm = () => {
      setClearModal(true);
    };

    const proceedToClear = status => {
      if (status) {
        if (basicInfoForm && basicInfoForm.current) {
          basicInfoForm.current.resetForm();
          supportingUrlsFormRef.current.reset();
          licensesFormRef.current.reset();
          phoneNumbersFormRef.current.reset();
          faxNumbersFormRef.current.reset();
          emailsFormRef.current.reset();
        }
        setNextReviewDate(originalPersonBasicInfo.recordDates.nextReviewDate);
        dispatch(resetPersonDetails(personAPIKeys.BASIC_INFO));
      } else {
        setClearModal(false);
      }
    };

    const onChangeUrls = updatedSupportingUrls => {
      if (basicInfoForm && basicInfoForm.current) {
        const basicInfoData = { ...basicInfoForm.current.values };
        basicInfoData.supportingUrls = updatedSupportingUrls;
        dispatch(
          updatePersonDetails({
            key: personAPIKeys.BASIC_INFO,
            data: {
              ...basicInfoData,
            },
          }),
        );
      }
    };

    const getSaveBodyPostParams = (savePostBodyParam, isValidRequired = true) => {
      let postParam = savePostBodyParam;
      if (id === 0 && ingestedId) {
        postParam = { ...postParam, prodStatusID: ingestedProductionStatusId };
      }
      const recordInceptionDateInfo = addInceptionDate(recordInceptionDate, inceptionDate);
      return { ...postParam, ...recordInceptionDateInfo, isValidationRequired: isValidRequired };
    };

    const personValidationModalHandleClick = value => {
      if (value === 'cancel') {
        dispatch(setShowPersonValidationModal({ isOpen: false }));
      } else {
        handleSave(null, false);
      }
    };

    return (
      <div className="person-basic-info-wrapper">
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid xs={12} item className="separate-line">
            <FeatureFooter
              handleSaveAndNext={() => handleSave(data)}
              handleSave={() => handleSave(null)}
              handleClear={() => resetPersonForm()}
              disabled={disableEdit}
            />

            <Grid container direction="row" justify="center" alignItems="flex-start" spacing={3}>
              <Grid item xs={12}>
                <Formik
                  key={id.toString()}
                  initialValues={initialValues}
                  onSubmit={async () => {
                    const {
                      data: basicInfo,
                      ingestedContentResults,
                      tabData,
                      isValidRequired,
                    } = updatedBasicAndContactInfoDetails;
                    const updatedData = getPersonBasicForAPI({
                      basicInfo,
                      nextReviewDate: nextReviewDate
                        ? new Date(nextReviewDate).toDateString()
                        : null,
                      ingestedId,
                      ingestedContentResults,
                    });
                    if (!isValidRequired) dispatch(setShowPersonValidationModal({ isOpen: false }));
                    dispatch(
                      savePersonDetails({
                        body: getSaveBodyPostParams(updatedData, isValidRequired),
                        tabData,
                        key: personAPIKeys.BASIC_INFO,
                      }),
                    );
                  }}
                  validate={async values => validate(values)}
                  enableReinitialize={true}
                >
                  {props => {
                    basicInfoForm.current = props;
                    return (
                      <FormBuilder
                        formikProps={props}
                        layoutSchema={layoutSchema}
                        formSchema={updatedFormSchema}
                        onSubmitValidationError={() => {}}
                        disableControls={disableEdit}
                      />
                    );
                  }}
                </Formik>
              </Grid>
            </Grid>
            <div className="mt-20">
              <Grid item xs={12}>
                <div className="person-urls-label">
                  <InputLabel size="16" text="SUPPORTING URLs" fontStyle="bold" />
                </div>
                <SupportingUrls
                  data={personBasicInfo.supportingUrls || []}
                  updateData={onChangeUrls}
                  statuses={supportingUrlStatuses}
                  disableEdit={disableEdit}
                  id={id}
                  ref={supportingUrlsFormRef}
                ></SupportingUrls>
              </Grid>
            </div>
            <div className="timestamp-container mt-20 margin-top-20">
              <InputLabel text="Record Dates" size="16" fontStyle="bold" />
              <TimeStamp
                data={{ ...personBasicInfo.recordDates, nextReviewDate }}
                handleDateChange={date => handleDateChange(date)}
                disabled={disableEdit || id == false}
              />
            </div>
          </Grid>
        </Grid>
        {updateNextReviewConfirmation && (
          <ConfirmationMessage
            isOpen
            onClose={proceedToUpdateNextReviewDate}
            message="Next Review Data has been over written. Are you sure you want to continue?"
          />
        )}

        {clearModal && <ClearConfirmation isChanged={isChanged} onClose={proceedToClear} />}
        {showPersonValidationModal && (
          <PersonValidationModal
            isOpen={showPersonValidationModal}
            clickHandler={personValidationModalHandleClick}
            personValidationMessages={personValidationMessages}
          ></PersonValidationModal>
        )}
      </div>
    );
  },
);

BasicAndContactInfo.propTypes = {
  data: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default BasicAndContactInfo;
